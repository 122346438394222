import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"
import CheckList from "./CheckList"
import { ScrollToElement } from "./hooks"
import "./Content.scss"
const close = "../../../../images/icons/alea-icon-close.png"

const CheckListPage = ({
  check,
  anchor,
  checkPage,
  selected,
  page,
  temp,
  stepArray,
  pageLabels,
}) => {
  const { checkList } = check
  const { setShowCheckPage } = checkPage
  const { setTempId } = temp
  const { isAnchor, setIsAnchor } = anchor
  const { isSelectedStep, setIsSelectedStep } = selected
  const checklistTodoTitle = pageLabels.toDoChecklistTitle
  const completeChecklistTitle = pageLabels.completeChecklistTitle
  const startNexButton = pageLabels.nextStepbutton
  const restartButton = pageLabels.restartStepButton
  const pageTitle = pageLabels.checkListPageTitle
  const { setStepPage } = page

  const nextStep = () => {
    setIsSelectedStep(stepArray[isAnchor.stepIndex + 1])
    setTempId(
      stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0].id
    )
    setStepPage(stepArray[isAnchor.stepIndex + 1].guide_step_items[0])
    setIsAnchor({
      ...isAnchor,
      stepIndex: isAnchor.stepIndex + 1,
      pageIndex: 0,
      elementId:
        stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
          .id,
    })
    setShowCheckPage(false)
    document.body.style.overflow = "unset"
    setTimeout(() => {
      ScrollToElement(
        stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
          .id
      )
    }, 200)
  }

  const restartStep = () => {
    setTempId(isSelectedStep.guide_step_items[0]?.guideContent[0].id)
    setStepPage(isSelectedStep.guide_step_items[0])
    setIsAnchor({
      ...isAnchor,
      pageIndex: 0,
      elementId: isSelectedStep.guide_step_items[0]?.guideContent[0].id,
    })
    setShowCheckPage(false)
    document.body.style.overflow = "unset"
    setTimeout(() => {
      ScrollToElement(isSelectedStep.guide_step_items[0]?.guideContent[0].id)
    }, 200)
  }

  const closePage = () => {
    document.body.style.overflow = "unset"
    setShowCheckPage(false)
  }

  return (
    <div className=" tw-fixed tw-p-2 tw-inset-0 tw-z-[9999] tw-bg-black tw-opacity-95 tw-h-screen tw-w-full tw-overflow-y-auto">
      <div className=" tw-flex tw-flex-wrap tw-basis-full tw-justify-center tw-content-start tw-h-full tw-z-50 ">
        <div
          className=" tw-basis-full tw-flex  tw-justify-end tw-mx-20 tw-pt-10"
          onClick={() => closePage()}
          onKeyDown={() => closePage()}
          role={`button`}
          tabIndex={-1}
        >
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            src={close}
            alt=""
            // width={28}
            // height={28}
          />
        </div>
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../../images/hr-guide/completeStepIcon.png"
          alt=""
          layout="constrained"
          width={60}
          height={60}
        />
        <div className=" tw-basis-full tw-mt-4  tw-text-white tw-text-center tw-text-2xl">
          <CustomMarkdown
            html={pageTitle}
            replaceText={{ old: "STEPS", new: isSelectedStep.stepName }}
          />
        </div>
        <div className=" tw-flex tw-basis-full tw-flex-wrap tw-justify-center tw-max-h-fit ">
          {checkList.find(
            checklist =>
              checklist.stepIndex <= isAnchor.stepIndex &&
              checklist.value === false
          ) && (
            <div className=" md:tw-basis-full tw-basis-1/3 tw-max-h-fit tw-px-2">
              <CheckList
                check={check}
                content={checkList.filter(
                  check =>
                    check.stepIndex <= isAnchor.stepIndex &&
                    check.value === false
                )}
                anchor={anchor}
                type="final"
                title={checklistTodoTitle}
                stepArray={stepArray}
                page={page}
                selected={selected}
                temp={temp}
                checkPg={checkPage}
              />
            </div>
          )}
          {checkList.find(
            checklist =>
              checklist.stepIndex <= isAnchor.stepIndex &&
              checklist.value === true
          ) && (
            <div className=" md:tw-basis-full tw-basis-1/3 tw-max-h-fit tw-px-2">
              <CheckList
                check={check}
                content={checkList.filter(
                  check =>
                    check.stepIndex <= isAnchor.stepIndex &&
                    check.value === true
                )}
                anchor={anchor}
                type="final"
                title={completeChecklistTitle}
                stepArray={stepArray}
                page={page}
                selected={selected}
                temp={temp}
                checkPg={checkPage}
                disabled={true}
              />
            </div>
          )}
        </div>
        <div className=" tw-grid tw-grid-rows-2 tw-gap-4 tw-p-4">
          <div
            role="button"
            className={` tw-w-32 tw-max-w-xs tw-bg-ming tw-p-1 tw-text-center tw-text-white hover:tw-opacity-80 tw-rounded-md`}
            onClick={() => nextStep()}
            onKeyDown={() => nextStep()}
            tabIndex={-1}
          >
            {startNexButton}
          </div>
          <div
            role="button"
            className={` tw-w-32 tw-max-w-xs tw-p-1 tw-bg-transparent hover:tw-opacity-80 tw-text-center tw-text-white tw-rounded-md`}
            onClick={() => restartStep()}
            onKeyDown={() => restartStep()}
            tabIndex={-2}
          >
            {restartButton}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CheckListPage
