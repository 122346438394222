import React from "react"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"
import { InView } from "react-intersection-observer"

const ElementDiv = ({ item, view, stInd, pgInd, anchor }) => {
  const { isAnchor } = anchor
  return (
    <>
      {stInd === isAnchor.stepIndex && pgInd === isAnchor.pageIndex && (
        <>
          {item.guideContent.map((content, index) => (
            <InView key={index} onChange={inView => view(inView, content.id)}>
              {({ ref }) => (
                <div
                  ref={ref}
                  id={content.id}
                  className={`customMarkdown tw-pt-4 tw-animate-fade-in-right tw-text-ming ${
                    stInd === isAnchor.stepIndex && pgInd === isAnchor.pageIndex
                      ? "tw-static"
                      : "tw-hidden"
                  }`}
                >
                  <CustomMarkdown
                    html={content.content}
                    toSanitize={false}
                    replaceText={{ old: "\n", new: "<br />" }}
                  ></CustomMarkdown>
                </div>
              )}
            </InView>
          ))}
        </>
      )}
    </>
  )
}

export default ElementDiv
