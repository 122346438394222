import React from "react"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"
import GlossaryTips from "./GlossaryTips"
import InfoBox from "./InfoBox"
import "./MobileContent.scss"
import { InView } from "react-intersection-observer"
import { ScrollToElement } from "./hooks"
import CheckList from "./CheckList"
import CheckListPage from "./CheckListPage"
import CaseStudy from "./CaseStudy"

const MobileContent = ({
  selected,
  anchor,
  page,
  stepArray,
  temp,
  menu,
  check,
  checkPage,
  pageLabels,
  advisor,
  lang,
  location,
  title,
}) => {
  const { isAnchor, setIsAnchor } = anchor
  const { isSelectedStep, setIsSelectedStep } = selected
  const { setStepPage } = page
  const { setTempId } = temp
  const { checkList } = check
  const { showCheckPage, setShowCheckPage } = checkPage
  const backButtonLabel = pageLabels.navBack
  const completeStepButtonLabel = pageLabels.completeStepButton
  const startHRGuideButtonLabel = pageLabels.startHRGuideButton
  const checklistTodoTitle = pageLabels.toDoChecklistTitle
  const handleClick = type => {
    if (type === "next") {
      setIsAnchor({
        ...isAnchor,
        stepIndex: isAnchor.stepIndex + 1,
        pageIndex: 0,
        elementId:
          stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
            .id,
      })
      setIsSelectedStep(stepArray[isAnchor.stepIndex + 1])
      setStepPage(stepArray[isAnchor.stepIndex + 1].guide_step_items[0])
      setTimeout(() => {
        ScrollToElement(
          stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
            .id
        )
      }, 200)
    } else if (type === "back") {
      setIsAnchor({
        ...isAnchor,
        stepIndex: isAnchor.stepIndex - 1,
        pageIndex: 0,
        elementId:
          stepArray[isAnchor.stepIndex - 1].guide_step_items[0]?.guideContent[0]
            .id,
      })
      setIsSelectedStep(stepArray[isAnchor.stepIndex - 1])
      setStepPage(stepArray[isAnchor.stepIndex - 1].guide_step_items[0])
      setTimeout(() => {
        ScrollToElement(
          stepArray[isAnchor.stepIndex - 1].guide_step_items[0]?.guideContent[0]
            .id
        )
      }, 200)
    }
  }

  const handleComplete = () => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden"
    }
    setShowCheckPage(true)
  }

  const scrollCheck = (inView, id) => {
    if (
      inView &&
      menu.items[isAnchor.stepIndex]?.children.items?.find(el => el.id === id)
    ) {
      setTempId(id)
    }
  }

  return (
    <div className="md:tw-flex tw-hidden tw-flex-wrap tw-h-full tw-divide-x-2 tw-divide-grey-100 tw-basis-full">
      <div
        className={`tw-bg-contentBg md:tw-basis-full tw-basis-7/12 tw-h-[calc(100vh_-_100px)] tw-overflow-y-auto scrollContentMobile`}
      >
        <div className="tw-p-9">
          <h1 className=" tw-font-extrabold tw-text-ming tw-text-center tw-bg-beige-100 tw-text-sm tw-py-2 tw-rounded-lg tw-shadow-md tw-mt-2 tw-border-solid tw-border-0  tw-border-ming">
            {title}
          </h1>
          {isSelectedStep.guide_step_items.map((subStep, index) => (
            <React.Fragment key={index}>
              {subStep.guideContent.map(content => (
                <React.Fragment key={content.id}>
                  <InView onChange={inView => scrollCheck(inView, content.id)}>
                    {({ ref }) => (
                      <div
                        ref={ref}
                        id={`mobile_${content.id}`}
                        className={`customMarkdown tw-text-ming tw-pt-4 tw-scroll-mt-20 tw-scroll-mb-20 `}
                      >
                        <CustomMarkdown html={content.content}></CustomMarkdown>
                      </div>
                    )}
                  </InView>
                  <div className="">
                    {content?.glossary_tips.map((glossary, index) => (
                      <React.Fragment key={index}>
                        <GlossaryTips content={glossary.content} />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="">
                    {content?.highlight.map((content, index) => (
                      <React.Fragment key={index}>
                        <InfoBox
                          content={content}
                          advisor={advisor}
                          lang={lang}
                          location={location}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                  <div className="">
                    {content?.case_study.map((content, index) => (
                      <React.Fragment key={index}>
                        <CaseStudy content={content} />
                      </React.Fragment>
                    ))}
                  </div>
                </React.Fragment>
              ))}
              {subStep.checklist && subStep.checklist.length > 0 && (
                <CheckList
                  check={check}
                  content={checkList.filter(
                    check => check.stepIndex === isAnchor.stepIndex
                  )}
                  anchor={anchor}
                  type="sub"
                  title={checklistTodoTitle}
                  stepArray={stepArray}
                  page={page}
                  selected={selected}
                  temp={temp}
                  checkPg={checkPage}
                />
              )}
              {subStep.enableContentChecklist === true && (
                <CheckList
                  check={check}
                  content={checkList}
                  anchor={anchor}
                  type="sub"
                  title={checklistTodoTitle}
                  stepArray={stepArray}
                  page={page}
                  selected={selected}
                  temp={temp}
                  checkPg={checkPage}
                />
              )}
            </React.Fragment>
          ))}
          {/* {checkList[isAnchor.stepIndex][isAnchor.pageIndex] &&
            checkList[isAnchor.stepIndex][isAnchor.pageIndex].length > 0 && (
              <div className={`tw-animate-fade-in-right`}>
                <CheckList
                  check={check}
                  content={checkList[isAnchor.stepIndex][isAnchor.pageIndex]}
                  anchor={isAnchor}
                  type="sub"
                />
              </div>
            )} */}
          <div className=" tw-flex tw-h-full tw-justify-center tw-gap-4 tw-items-center">
            {isAnchor.stepIndex > 0 && (
              <div
                role="button"
                className={`tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md`}
                onClick={() => handleClick("back")}
                onKeyDown={() => handleClick("back")}
                tabIndex={-1}
              >
                {backButtonLabel}
              </div>
            )}
            {isAnchor.stepIndex + 1 < stepArray.length &&
              isSelectedStep.enablePreface === false && (
                <div
                  role="button"
                  className={`tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md`}
                  onClick={() => handleComplete()}
                  onKeyDown={() => handleComplete()}
                  tabIndex={-2}
                >
                  {completeStepButtonLabel}
                </div>
              )}
            {isAnchor.stepIndex + 1 < stepArray.length &&
              isSelectedStep.enablePreface === true && (
                <div
                  role="button"
                  className={`tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md`}
                  onClick={() => handleClick("next")}
                  onKeyDown={() => handleClick("next")}
                  tabIndex={-3}
                >
                  {startHRGuideButtonLabel}
                </div>
              )}
          </div>
          {showCheckPage && (
            <CheckListPage
              check={check}
              anchor={anchor}
              checkPage={{ showCheckPage, setShowCheckPage }}
              selected={selected}
              page={page}
              temp={temp}
              stepArray={stepArray}
              pageLabels={pageLabels}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MobileContent
