import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"
const spellCheck = "../../../../images/icons/spell-check.png"

const GlossaryTips = ({ content }) => {
  return (
    <div className=" tw-relative tw-bg-beige-300 tw-rounded-xl tw-p-6 tw-my-8 tw-m-2 tw-soft tw-shadow-xl tw-text-ming ">
      <div className=" tw-absolute -tw-top-4 -tw-left-4">
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={spellCheck}
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      </div>
      <CustomMarkdown
        html={content}
        classnames={"tw-font-lora tw-leading-7 tw-text-sm tw-italic"}
        color="copper"
      />
    </div>
  )
}

export default GlossaryTips
