import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { getGatsbyImage } from "../../../../plugins/common"

const MenuButton = ({ item, checkSelected, anchor, stepInd }) => {
  const { isAnchor } = anchor

  return (
    <div
      className={`tw-grid tw-relative tw-gap-3 tw-justify-items-center tw-content-center tw-cursor-pointer tw-rounded-l-xl tw-mt-2 tw-ml-2 hover:tw-bg-neutral-200 tw-h-18  ${
        isAnchor.stepIndex === stepInd && "tw-bg-white tw-shadow-md"
      }`}
      onClick={() => checkSelected(item, stepInd)}
      onKeyDown={() => checkSelected(item, stepInd)}
      role={`button`}
      tabIndex={-1}
    >
      <div
        className={`tw-pt-2 tw-h-8 tw-w-8`}
      >
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          image={getGatsbyImage(item.stepImage)}
          alt=""
          objectFit="contain"
          className={`${
            isAnchor.stepIndex === stepInd
              ? "tw-brightness-0"
              : "tw-brightness-100"
          }`}
        />
      </div>
      <div
        className={`tw-basis-full  tw-text-center tw-text-xs tw-pb-2 ${
          isAnchor.stepIndex === stepInd ? "tw-text-ming " : "tw-text-beige-200"
        }`}
      >
        {item.stepName}
      </div>
    </div>
  )
}

export default MenuButton
