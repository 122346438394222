import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React from "react"
import { getGatsbyImage } from "../../../../plugins/common"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"

const CaseStudy = ({ content }) => {
  const caseBanner = "../../../../images/hr-guide/case-study-banner.png"
  return (
    <div className="tw-relative tw-bg-white tw-rounded-xl tw-p-6 tw-m-2 tw-my-8 tw-soft tw-shadow-xl tw-text-ming ">
      <p className=" tw-left-20 tw-right-2 md:-tw-top-2 tw-z-30 tw-top-2 tw-absolute tw-text-md tw-font-lora tw-font-extrabold tw-truncate">
        {content.title}
      </p>
      <p className=" tw-left-20 md:tw-top-5 tw-top-10 tw-z-30 tw-absolute tw-text-xs tw-font-extralight tw-truncate">
        {content.subtitle}
      </p>
      <div className=" tw-absolute tw-top-0 tw-left-0 tw-right-0">
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={caseBanner}
          alt=""
          layout="constrained"
          objectFit="fill"
        />
      </div>
      <div className=" tw-absolute md:tw-top-1 tw-top-6 tw-left-4 tw-h-12 tw-w-12">
        <GatsbyImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          image={getGatsbyImage(content.icon)}
          alt="alea"
        />
      </div>
      <CustomMarkdown
        html={content.content}
        classnames={" tw-font-lora tw-leading-7 tw-text-sm tw-mt-24"}
        color="copper"
      />
    </div>
  )
}

export default CaseStudy
