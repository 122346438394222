const { useEffect, useState, useRef } = require("react")

const UseIsInViewport = id => {
  const [isIntersecting, setIsIntersecting] = useState(false)
  const observer = useRef(null)

  useEffect(() => {
    if (observer.current) observer.current.disconnect()

    observer.current = new IntersectionObserver(([entry]) =>
      setIsIntersecting(entry.isIntersecting)
    )
    observer.current.observe(document.getElementById(id))

    return () => {
      observer.current.disconnect()
    }
  }, [id, observer])
  return isIntersecting
}

const ScrollToElement = id => {
  const el = document.getElementById(id)
  const elMobile = document.getElementById(`mobile_${id}`)
  el &&
    el.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    })
  elMobile &&
    elMobile.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "start",
    })
}

module.exports = {
  UseIsInViewport,
  ScrollToElement,
}
