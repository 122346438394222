import React from "react"
import useCollapse from "react-collapsed"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import Icon from "@mdi/react"
import "./Anchor.scss"

const Collapsible = props => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <div className={`collapsible ${props.style}`}>
      <div className="header">
        <div
          className="title tw-text-sm  hover:tw-text-neutral-400"
          onClick={() => props.anchorClick(props.anchorId)}
          onKeyDown={() => props.anchorClick(props.anchorId)}
          role={`button`}
          tabIndex={-1}
        >
          {props.title}
        </div>
        {props.children && (
          <div
            className="icon tw-border-none tw-border-0 tw-bg-transparent"
            {...getToggleProps()}
          >
            <Icon path={isExpanded ? mdiChevronUp : mdiChevronDown} size={1} />
          </div>
        )}
      </div>
      <div {...getCollapseProps()}>
        <div className="content">{props.children}</div>
      </div>
    </div>
  )
}

export default Collapsible
