import React, { useState } from "react"
import "./Content.scss"
import ElementDiv from "./ElementDiv"
import { ScrollToElement } from "./hooks"
import RightCol from "./RightCol"
import QuoteForm from "../../../get-a-quote/components/QuoteForm"
import { staticText } from "../../../../enumerator/staticText"
import MobileContent from "./MobileContent"
import CheckList from "./CheckList"
import CheckListPage from "./CheckListPage"
import Partners from "../../../resource-hub/components/Partners"
import { StaticImage } from "gatsby-plugin-image"
const mobileMenu = "../../../../images/hr-guide/mdi_arrow-collapse-down.png"

const Content = ({
  selected,
  stepPage,
  anchor,
  translation,
  getAQuote,
  lang,
  token,
  location,
  affiliateForm,
  stepArray,
  temp,
  menu,
  check,
  inView,
  advisor,
  pageLabels,
  partners,
  open,
  setOpen,
  title,
}) => {
  const { isSelectedStep, setIsSelectedStep } = selected
  const { isStepPage, setStepPage } = stepPage
  const { isAnchor, setIsAnchor } = anchor
  const { checkList } = check
  const backButtonLabel = pageLabels.navBack
  const nextButtonLabel = pageLabels.navNext
  const completeStepButtonLabel = pageLabels.completeStepButton
  const startHRGuideButtonLabel = pageLabels.startHRGuideButton
  const checklistTodoTitle = pageLabels.toDoChecklistTitle
  const { tempId, setTempId } = temp
  const { elInView, setElInView } = inView
  const [showCheckPage, setShowCheckPage] = useState(false)

  const handleClick = type => {
    const currentIndex = isSelectedStep.guide_step_items.indexOf(
      isSelectedStep.guide_step_items.find(
        el => el.strapiId === isStepPage.strapiId
      )
    )
    if (type === "next") {
      if (
        isSelectedStep.guide_step_items[currentIndex + 1]?.guideContent[0]
          .anchorLevel === "h4" &&
        isSelectedStep.guide_step_items[currentIndex]?.guideContent.find(
          level => level.anchorLevel === "h3"
        )?.id
      ) {
        setTempId(
          isSelectedStep.guide_step_items[currentIndex]?.guideContent.find(
            level => level.anchorLevel === "h3"
          )?.id
        )
      } else if (
        isSelectedStep.guide_step_items[currentIndex + 1]?.guideContent[0]
          .anchorLevel === "h3"
      ) {
        setTempId(
          isSelectedStep.guide_step_items[currentIndex + 1]?.guideContent[0].id
        )
      }
      setStepPage(isSelectedStep.guide_step_items[currentIndex + 1])
      setIsAnchor({
        ...isAnchor,
        pageIndex: currentIndex + 1,
        elementId:
          isSelectedStep.guide_step_items[currentIndex + 1]?.guideContent[0].id,
      })
      setTimeout(() => {
        ScrollToElement(
          isSelectedStep.guide_step_items[currentIndex + 1]?.guideContent[0].id
        )
      }, 200)
    } else if (type === "back") {
      for (
        let index = 1;
        index < isSelectedStep.guide_step_items.length;
        index++
      ) {
        if (
          isSelectedStep.guide_step_items[
            currentIndex - index
          ]?.guideContent.find(el => el.anchorLevel === "h3")
        ) {
          setTempId(
            isSelectedStep.guide_step_items[
              currentIndex - index
            ]?.guideContent.find(el => el.anchorLevel === "h3").id
          )
        } else if (
          isSelectedStep.guide_step_items[
            currentIndex - index
          ]?.guideContent.find(el => el.anchorLevel === "h2")
        ) {
          setTempId(
            isSelectedStep.guide_step_items[
              currentIndex - index
            ]?.guideContent.find(el => el.anchorLevel === "h2").id
          )
        }
      }

      setStepPage(isSelectedStep.guide_step_items[currentIndex - 1])
      setIsAnchor({
        ...isAnchor,
        pageIndex: currentIndex - 1,
        elementId:
          isSelectedStep.guide_step_items[currentIndex - 1]?.guideContent[0].id,
      })
      setTimeout(() => {
        ScrollToElement(
          isSelectedStep.guide_step_items[currentIndex - 1]?.guideContent[0].id
        )
      }, 200)
    } else if (type === "start") {
      setIsSelectedStep(stepArray[isAnchor.stepIndex + 1])
      setTempId(
        stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
          .id
      )
      setStepPage(stepArray[isAnchor.stepIndex + 1].guide_step_items[0])
      setIsAnchor({
        ...isAnchor,
        stepIndex: isAnchor.stepIndex + 1,
        pageIndex: 0,
        elementId:
          stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
            .id,
      })
      setTimeout(() => {
        ScrollToElement(
          stepArray[isAnchor.stepIndex + 1].guide_step_items[0]?.guideContent[0]
            .id
        )
      }, 200)
    }
  }
  // console.log("tempId", tempId)

  const handleComplete = () => {
    if (typeof window != "undefined" && window.document) {
      document.body.style.overflow = "hidden"
    }
    setShowCheckPage(true)
  }

  const checkView = (inView, id) => {
    if (inView && !elInView.includes(id)) {
      setElInView(currentArray => [...currentArray, id])
    } else if (!inView) {
      setElInView(elInView.filter(el => el !== id))
    }
  }

  return (
    <div
      className={`tw-bg-contentBg tw-shadow tw-flex-1 tw-min-h-full tw-basis-9/12`}
    >
      {/* DESKTOP CONTENT */}
      <div className="md:tw-hidden tw-relative tw-flex tw-flex-wrap tw-h-full tw-divide-solid tw-divide-y-0 tw-divide-x-2 tw-divide-grey-100 tw-basis-full">
        {/* <div className={`tw-bg-contentBg lg:tw-basis-7/12 tw-h-full`}>
          <div className="tw-h-[96%] tw-overflow-y-auto scrollContent"> */}
        <div className={`tw-bg-contentBg tw-basis-7/12 tw-h-full`}>
          <div className="tw-h-[96%] tw-overflow-y-auto scrollContent">
            <div
              className=" md:tw-hidden tw-block tw-sticky tw-top-5 tw-px-1.5 tw-cursor-pointer hover:tw-opacity-80"
              onClick={() => setOpen(!open)}
              onKeyDown={() => setOpen(!open)}
              role={`button`}
              tabIndex={-1}
            >
              <StaticImage
                sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
                src={mobileMenu}
                alt=""
                width={25}
                height={25}
                className={` tw-duration-300 ${open ? "tw-rotate-180" : ""} `}
              />
            </div>
            <div className="tw-px-10 tw-pb-8">
              {stepArray.map((step, stepIndex) =>
                step.guide_step_items.map((item, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    <ElementDiv
                      item={item}
                      stInd={stepIndex}
                      pgInd={pageIndex}
                      view={checkView}
                      anchor={anchor}
                    />
                    {stepIndex === isAnchor.stepIndex &&
                      pageIndex === isAnchor.pageIndex &&
                      item.enableContentChecklist === true && (
                        <CheckList
                          check={check}
                          content={checkList}
                          anchor={anchor}
                          type="sub"
                          title={checklistTodoTitle}
                          stepArray={stepArray}
                          page={stepPage}
                          selected={selected}
                          temp={temp}
                          checkPg={{ showCheckPage, setShowCheckPage }}
                        />
                      )}
                  </React.Fragment>
                ))
              )}
            </div>
            <div className=" tw-absolute tw-bottom-[4%] tw-bg-gradient-to-t tw-from-contentBg tw-h-20 tw-w-full"></div>
          </div>
          <div className=" tw-h-[8%]"></div>
        </div>
        <div
          className={`tw-bg-contentBg tw-h-full md:tw-basis-full tw-basis-5/12 tw-relative`}
        >
          <div className=" tw-h-[92%] tw-overflow-y-auto tw-p-10 scrollContent">
            {isStepPage?.guideContent.map((item, index) => (
              <RightCol
                key={index}
                itemId={item.id}
                item={item}
                inView={elInView}
                advisor={advisor}
                lang={lang}
                location={location}
              />
            ))}
            {isStepPage?.guideContent
              .filter(el => elInView.includes(el.id))
              .filter(
                l => l.glossary_tips?.length !== 0 || l.highlight?.length !== 0
              ).length === 0 && (
              <>
                <div className=" tw-animate-fade-in-left">
                  <QuoteForm
                    getAQuote={getAQuote}
                    lang={lang}
                    token={token}
                    location={location}
                    translation={translation}
                    handleQuoteType={() => {}}
                    type={staticText.HRGUIDE}
                    affiliateForm={affiliateForm}
                    hanndleSubmitted={false}
                  />
                </div>
                <Partners data={partners} lang={lang} type={"guide"} />
              </>
            )}

            {checkList.find(
              check =>
                check.stepIndex === isAnchor.stepIndex &&
                check.pageIndex === isAnchor.pageIndex
            ) && (
              // <div className={`tw-animate-fade-in-right`}>
              <CheckList
                check={check}
                content={checkList.filter(
                  check =>
                    check.stepIndex === isAnchor.stepIndex &&
                    check.pageIndex === isAnchor.pageIndex
                )}
                anchor={anchor}
                type="sub"
                title={checklistTodoTitle}
                stepArray={stepArray}
                page={stepPage}
                selected={selected}
                temp={temp}
                checkPg={{ showCheckPage, setShowCheckPage }}
              />
              // </div>
            )}
            {/* {isAnchor.stepIndex === stepArray.length - 1 && (
              <Partners data={partners} lang={lang} type={"guide"} />
            )} */}
          </div>
          <div className=" tw-absolute tw-bottom-[8%] tw-bg-gradient-to-t tw-from-contentBg tw-w-full tw-h-10"></div>
          <div className="tw-h-[8%] tw-bg-contentBg">
            <div className=" tw-flex tw-h-full tw-justify-center tw-gap-4 tw-items-center">
              {isSelectedStep.guide_step_items.find(
                el => el.strapiId < isStepPage?.strapiId
              ) && (
                <div
                  role="button"
                  className={` tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md hover:tw-opacity-90`}
                  onClick={() => handleClick("back")}
                  onKeyDown={() => handleClick("back")}
                  tabIndex={-1}
                >
                  {backButtonLabel}
                </div>
              )}
              {isSelectedStep.guide_step_items.find(
                el => el.strapiId > isStepPage?.strapiId
              ) && (
                <div
                  role="button"
                  className={` tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md hover:tw-opacity-90`}
                  onClick={() => handleClick("next")}
                  onKeyDown={() => handleClick("next")}
                  tabIndex={-2}
                >
                  {nextButtonLabel}
                </div>
              )}
              {!isSelectedStep.guide_step_items.find(
                el => el.strapiId > isStepPage?.strapiId
              ) &&
                isSelectedStep.enablePreface === true && (
                  <div
                    role="button"
                    className={` tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md hover:tw-opacity-90`}
                    onClick={() => handleClick("start")}
                    onKeyDown={() => handleClick("start")}
                    tabIndex={-3}
                  >
                    {startHRGuideButtonLabel}
                  </div>
                )}
              {!isSelectedStep.guide_step_items.find(
                el => el.strapiId > isStepPage?.strapiId
              ) &&
                isSelectedStep.enablePreface === false &&
                isAnchor.stepIndex !== stepArray.length - 1 && (
                  <div
                    role="button"
                    className={` tw-basis-1/3 tw-max-h-min tw-border tw-p-1 tw-bg-ming tw-text-center tw-text-white tw-rounded-md hover:tw-opacity-90`}
                    onClick={() => handleComplete()}
                    onKeyDown={() => handleComplete()}
                    tabIndex={-4}
                  >
                    {completeStepButtonLabel}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE CONTENT */}
      <MobileContent
        selected={selected}
        page={stepPage}
        anchor={anchor}
        stepArray={stepArray}
        temp={temp}
        menu={menu}
        check={check}
        checkPage={{ showCheckPage, setShowCheckPage }}
        pageLabels={pageLabels}
        advisor={advisor}
        lang={lang}
        location={location}
        title={title}
      ></MobileContent>
      {showCheckPage && (
        <CheckListPage
          check={check}
          anchor={anchor}
          checkPage={{ showCheckPage, setShowCheckPage }}
          selected={selected}
          page={stepPage}
          temp={temp}
          stepArray={stepArray}
          pageLabels={pageLabels}
        />
      )}
    </div>
  )
}

export default Content
