import React from "react"
import Anchor from "./Anchor"
import MenuButton from "./MenuButton"
import MobileDropdown from "./MobileDropdown"

const Navigation = ({
  stepArray,
  selected,
  open,
  setOpen,
  menu,
  anchor,
  stepPage,
  temp,
  inView,
}) => {
  const { setIsSelectedStep } = selected
  const { isAnchor, setIsAnchor } = anchor
  const { setElInView } = inView
  const { setStepPage } = stepPage
  const { setTempId } = temp
  const checkSelected = (item, ind) => {
    if (isAnchor?.stepIndex === ind) {
      setOpen(!open)
      return
    }
    setElInView([])
    setIsSelectedStep(item)
    setTempId(item.guide_step_items[0]?.guideContent[0].id)
    setStepPage(item.guide_step_items[0])
    setIsAnchor({
      ...isAnchor,
      stepIndex: ind,
      pageIndex: 0,
      elementId: item.guide_step_items[0]?.guideContent[0].id,
    })
  }

  return (
    <>
      <MobileDropdown
        stepArray={stepArray}
        selected={selected}
        page={stepPage}
        anchor={anchor}
        temp={temp}
      />
      <div
        className={`tw-bg-beige-100 tw-h-full tw-min-h-full md:tw-basis-full tw-basis-1/12 tw-duration-300`}
      >
        <div className="tw-flex-col tw-basis-full md:tw-hidden tw-flex">
          {stepArray.map((item, index) => (
            <MenuButton
              key={index}
              stepInd={index}
              item={item}
              checkSelected={checkSelected}
              anchor={anchor}
            />
          ))}
        </div>
      </div>
      <Anchor
        open={open}
        stepPage={stepPage}
        setOpen={setOpen}
        selected={selected}
        stepArray={stepArray}
        menu={menu}
        anchor={anchor}
        temp={temp}
      />
    </>
  )
}

export default Navigation
