import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Button from "../../../../components/Button"
import AdvisorMenu from "../../../../components/list/AdvisorMenu"
import { getGatsbyImage } from "../../../../plugins/common"
import { CustomMarkdown } from "../../../../plugins/sanitizehtml"

const InfoBox = ({ content, advisor, location, lang }) => {
  const aleaLogo = "../../../../images/Alea_logo_reversed.png"
  const [openAdvisor, setOpenAdvisor] = useState(false)
  const boxType = [
    {
      name: "",
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../../images/icons/expert-tips.png"
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      ),
    },
    {
      name: "Tips",
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../../images/icons/expert-tips.png"
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      ),
    },
    {
      name: "Toolkit",
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../../images/icons/tool-kit.png"
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      ),
    },
    {
      name: "Advisor",
      icon: "",
    },
    {
      name: "Insider_knowledge",
      label: "Insider knowledge",
      icon: (
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src="../../../../images/icons/light-bulb.png"
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      ),
    },
  ]
  const closeMenu = state => {
    if (state) {
      if (typeof window != "undefined" && window.document) {
        document.body.style.overflow = "hidden"
      }
      setOpenAdvisor(true)
    } else {
      setOpenAdvisor(false)
      document.body.style.overflow = "unset"
    }
  }

  const box = boxType.find(f => f.name === content.typeHighlight) ?? null

  if (!box) {
    return (
      <>
        <div className=" tw-relative tw-bg-beige-300 tw-rounded-xl tw-p-6 tw-m-2 tw-my-8 tw-soft tw-shadow-xl ">
          <CustomMarkdown
            html={content.content}
            classnames={"tw-font-lora tw-leading-7 tw-text-sm"}
            color="copper"
          />
        </div>
      </>
    )
  }
  return (
    <div
      className={` tw-relative ${
        box.name === "Advisor"
          ? "tw-bg-ming tw-text-white tw-font-sans tw-py-1"
          : "tw-bg-beige-300 tw-text-ming tw-py-4"
      }  tw-rounded-xl tw-px-6 tw-m-2 tw-my-8 tw-soft tw-shadow-xl `}
    >
      <div className=" tw-absolute -tw-top-4 -tw-left-4">{box.icon}</div>
      {box.name === "Advisor" && (
        <div className=" tw-absolute tw-top-2 tw-right-2 tw-w-16 tw-h-10">
          <StaticImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            src={aleaLogo}
            alt=""
            layout="constrained"
          />
        </div>
      )}
      <div className={`${box.name === "Advisor" ? "tw-pt-6" : ""}`}>
        <CustomMarkdown
          html={content.content}
          className={`${
            box.name === "Advisor" ? "tw-font-sans" : "tw-font-lora"
          } tw-leading-7 tw-text-sm`}
          color="copper"
        />
      </div>
      {content.cta && (
        // <div
        //   role="button"
        //   className={` tw-mx-12 tw-my-2 tw-border tw-bg-ming tw-text-center tw-text-white tw-rounded-md`}
        //   // onClick={() => handleClick("back")}
        //   // onKeyDown={() => handleClick("back")}
        // >
        //   {content.cta.buttonLabel}
        // </div>
        <div className="tw-text-center tw-text-white">
          <Button
            buttonId={`partners_footer_submit`}
            className={``}
            lang={lang}
            type={` primaryCornersMing`}
            text={content.cta.buttonLabel}
            url={content.cta.url}
          />
        </div>
      )}
      {content.imageCTA && (
        <div
          className=" tw-relative tw-bottom-4 hover:tw-scale-[102%] tw-cursor-pointer tw-transition"
          onClick={() => closeMenu(true)}
          onKeyDown={() => closeMenu(true)}
          role={`button`}
          tabIndex={-1}
        >
          <GatsbyImage
            sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
            image={getGatsbyImage(content.imageCTA)}
            alt=""
          />
        </div>
      )}
      {openAdvisor && (
        <AdvisorMenu
          advisors={advisor.advisorList?.our_teams}
          getQuoteSlug={advisor.slug}
          logo={advisor.footerLogo}
          data={advisor.advisorMenu?.advisorMenu}
          closeMenu={closeMenu}
          location={location}
          lang={lang}
        />
      )}
      <div>
        {content.footnote && (
          <CustomMarkdown
            html={content.footnote}
            classnames={"tw-font-lora tw-leading-7 tw-text-xs tw-italic"}
          />
        )}
      </div>
    </div>
  )
}

export default InfoBox
