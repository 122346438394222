import React, { useEffect, useRef } from "react"
import { CustomMarkdown } from "../../plugins/sanitizehtml"
import { openPopupWidget } from "react-calendly"
import Img from "../Img"
import Button from "../Button"
import headset from "../../images/icons/headset.svg"
import * as style from "./AdvisorMenu.module.scss"
import { updateUrlByLocale } from "../../plugins/common"

const AdvisorMenu = ({
  advisors,
  getQuoteSlug,
  insuranceType,
  logo,
  data,
  lang,
  location,
  closeMenu,
}) => {
  const { title, requestBtnLabel, callBtnLabel, calendlyUrl } = data
  const menuRef = useRef(null)

  const openCalendly = () => openPopupWidget({ url: calendlyUrl })

  const handleClickOutside = e => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      closeMenu(false)
    }
  }
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  return (
    <div className={`${style.advisorMenuWrapper} `}>
      <div
        ref={menuRef}
        className={`${style.advisorMenu} corners pop-up-ani mx-lg-auto mx-2`}
      >
        <div
          className={`flex ${style.header} align-items-center px-3 py-2 justify-content-between bg-ming white`}
        >
          <Img image={logo} className={style.logo} />

          <div className={`text-end openSans-Bold ${style.title}`}>
            <CustomMarkdown html={title} color="dead-salmon"></CustomMarkdown>
          </div>
        </div>
        <div className={`${style.menu}`}>
          {advisors.map((advisor, index) => (
            <div
              key={index}
              className={` ${style.advisorRow} px-3 py-2 d-flex justify-content-between gap-4`}
            >
              <div className={`${style.left}  align-self-center`}>
                <Img image={advisor.image[0]} className={style.image} />
              </div>
              <div className={`${style.middle} ming`}>
                <div className={`openSans-Bold ${style.name} mb-2`}>
                  {advisor.name}
                </div>
                {/* <div className={`openSans-SemiBold ${style.jobTitle}`}>
                  {advisor.jobTitle}
                </div> */}
                <div className={`openSans ${style.specialization}`}>
                  {advisor.specialization}
                </div>
              </div>
              <div className={`${style.right}`}>
                <Button
                  lang={lang}
                  type={`mingBox`}
                  url={
                    "/" +
                    getQuoteSlug +
                    `?from=${encodeURIComponent(location.href)}&advisor=${
                      advisor.name
                    }${insuranceType ? `&type=${insuranceType}` : ""}`
                  }
                >
                  {requestBtnLabel}
                </Button>
              </div>
            </div>
          ))}
          <div className={`text-center py-2 ${style.advisorRow}`}>
            <Button lang={lang} type={`mingBox`} event={openCalendly}>
              {callBtnLabel}
              <Img image={headset} className={`ms-3`} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdvisorMenu
