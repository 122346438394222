import React, { useEffect, useRef, useState } from "react"
import "./Anchor.scss"
import Collapsible from "./Collapsible"
import { ScrollToElement } from "./hooks"
import { StaticImage } from "gatsby-plugin-image"
const mobileMenu = "../../../../images/icons/mobile-menu.png"

const Anchor = ({ open, setOpen, selected, menu, anchor, stepPage, temp }) => {
  const { isSelectedStep } = selected
  const { setStepPage } = stepPage
  const { isAnchor, setIsAnchor } = anchor
  const { tempId, setTempId } = temp
  const ref = useRef(null)

  const handleClickOutside = event => {
    const isMobile = window.innerWidth < 1023
    if (ref.current && !ref.current.contains(event.target) && isMobile) {
      setOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  const anchorClick = (id, parentId) => {
    const anchorIndex = isSelectedStep.guide_step_items.indexOf(
      isSelectedStep.guide_step_items.find(el =>
        el.guideContent.find(l => l.id === id)
      )
    )
    if (isAnchor.pageIndex === anchorIndex) {
      setIsAnchor({ ...isAnchor, elementId: id })
      ScrollToElement(id)
    } else {
      setIsAnchor({
        ...isAnchor,
        pageIndex: anchorIndex,
        elementId: id,
      })
      setStepPage(isSelectedStep.guide_step_items[anchorIndex])
      setTimeout(() => {
        ScrollToElement(id)
      }, 200)
    }
    if (parentId) {
      setTempId(parentId)
    } else {
      setTempId(id)
    }
  }

  return (
    <>
      <div
        className={`md:tw-bg-beige2 tw-bg-white md:tw-rounded-lg tw-rounded-none tw-min-h-full md:tw-min-h-min tw-static md:tw-absolute tw-top-12 tw-w-2/3 tw-left-10 tw-z-30 tw-shadow-lg ${
          open ? "tw-basis-2/12" : "tw-basis-0"
        } tw-duration-300`}
        ref={ref}
      >
        {isSelectedStep && (
          <div
            className={`preferences ${
              open ? "tw-animate-fade-in-right" : "tw-hidden"
            } tw-border-solid tw-border-0 tw-border-l-8 tw-border-grey-100 tw-m-4`}
          >
            <div
              className={`text-md tw-cursor-pointer hover:tw-text-neutral-400 tw-text-center tw-font-loraBold ${
                menu.items[isAnchor.stepIndex]?.id === tempId &&
                "selectedAnchor"
              }`}
              onClick={() => anchorClick(menu.items[isAnchor.stepIndex]?.id)}
              onKeyDown={() => anchorClick(menu.items[isAnchor.stepIndex]?.id)}
              role={`button`}
              tabIndex={-1}
            >
              {isSelectedStep.stepName}
            </div>
            {menu.items[isAnchor.stepIndex]?.children.items?.map(
              (item, index) => (
                <Collapsible
                  key={index}
                  title={item.name}
                  anchorId={item.id}
                  style={`${item.id === tempId && "selectedAnchor"}`}
                  anchorClick={anchorClick}
                >
                  {item.children.length !== 0 && (
                    <>
                      {item.children.items.map((subitem, subindex) => (
                        <div
                          className={` tw-cursor-pointer hover:tw-text-neutral-400 tw-pl-2`}
                          key={subindex}
                          onClick={() => anchorClick(subitem.id, item.id)}
                          onKeyDown={() => anchorClick(subitem.id, item.id)}
                          role={`button`}
                          tabIndex={0 - subindex}
                        >
                          &bull; {subitem.name}
                        </div>
                      ))}
                    </>
                  )}
                </Collapsible>
              )
            )}
          </div>
        )}
      </div>
      <div
        className="tw-fixed md:tw-block tw-hidden tw-top-32 tw-left-1 tw-z-40"
        onClick={() => setOpen(!open)}
        onKeyDown={() => setOpen(!open)}
        role={`button`}
        tabIndex={-1}
      >
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={mobileMenu}
          alt=""
          width={28}
          height={28}
        />
      </div>
    </>
  )
}

export default Anchor
