import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { ScrollToElement } from "./hooks"
const checkListImage = "../../../../images/icons/checklist.png"

const CheckList = ({
  check,
  content,
  anchor,
  type,
  title,
  disabled,
  stepArray,
  page,
  selected,
  temp,
  checkPg,
}) => {
  const { setCheckList } = check
  const { isAnchor, setIsAnchor } = anchor
  const { setStepPage } = page
  const { setIsSelectedStep } = selected
  const { setTempId } = temp
  const { setShowCheckPage } = checkPg
  const handleChange = (event, check) => {
    if (event.target.checked) {
      setCheckList(current =>
        current.map(checklist => {
          if (checklist.id === check.id) {
            return { ...checklist, value: true }
          }
          return checklist
        })
      )
    } else {
      setCheckList(current =>
        current.map(checklist => {
          if (checklist.id === check.id) {
            return { ...checklist, value: false }
          }
          return checklist
        })
      )
    }
  }

  const handleClick = check => {
    setIsAnchor({
      ...isAnchor,
      stepIndex: check.stepIndex,
      pageIndex: check.pageIndex,
      elementId:
        stepArray[check.stepIndex].guide_step_items[check.pageIndex]
          .guideContent[0].id,
    })
    setStepPage(stepArray[check.stepIndex].guide_step_items[check.pageIndex])
    setIsSelectedStep(stepArray[check.stepIndex])
    setTempId(
      stepArray[check.stepIndex].guide_step_items[check.pageIndex]
        .guideContent[0].id
    )
    setTimeout(() => {
      ScrollToElement(
        stepArray[check.stepIndex].guide_step_items[check.pageIndex]
          .guideContent[0].id
      )
    }, 200)
    setShowCheckPage(false)
  }
  // console.log("checkList: ", checkList)
  // console.log("content: ", content)

  return (
    <div
      className={`tw-relative ${
        type === "final"
          ? "tw-bg-transparent tw-text-white"
          : "tw-bg-beige-300 tw-text-black"
      } tw-rounded-xl tw-border-solid tw-border-ming tw-border-2 tw-p-6 tw-my-8 tw-m-2 tw-soft tw-shadow-xl tw-animate-fade-in-left `}
    >
      <div className=" tw-absolute -tw-top-4 -tw-left-4">
        <StaticImage
          sizes="(max-width: 370px) 37w, (max-width: 500px) 50vw,(max-width: 800px) 80vw,  100vw"
          src={checkListImage}
          alt=""
          layout="constrained"
          width={50}
          height={50}
        />
      </div>
      <p className=" tw-text-lg tw-font-lora tw-font-extrabold tw-mb-2">
        {title}
      </p>
      {/* {type === "sub" && (
        <> */}
      {content.map((check, index) => (
        <div key={index}>
          {check.guide_checklist_group && (
            <>
              {content[index - 1]?.guide_checklist_group?.label !==
                check.guide_checklist_group?.label && (
                <p className=" tw-font-sans tw-font-semibold">
                  {check.guide_checklist_group.label}
                </p>
              )}
            </>
          )}
          {check.name && (
            <>
              <input
                className={``}
                type="checkbox"
                checked={check.value}
                onChange={e => handleChange(e, check)}
              />
              <span
                role={`button`}
                tabIndex={-1}
                onClick={() => handleClick(check)}
                onKeyDown={() => handleClick(check)}
              >
                <label
                  className={` tw-pl-2 tw-font-sans tw-font-light tw-inline ${
                    type === "final"
                      ? "hover:tw-text-neutral-400 tw-cursor-pointer"
                      : ""
                  } `}
                >
                  {check.name}
                </label>
              </span>
            </>
          )}
        </div>
      ))}
      {/* </>
      )} */}
      {/* {type === "final" && (
        <>
          {content.map((check, index) => (
            <div key={index}>
              {check.guide_checklist_group && (
                <>
                  {content[index - 1]?.guide_checklist_group?.label !==
                    check.guide_checklist_group?.label && (
                    <p className=" tw-font-sans tw-font-semibold ">
                      {check.guide_checklist_group.label}
                    </p>
                  )}
                </>
              )}
              <input
                className={``}
                type="checkbox"
                checked={check.value}
                onChange={e => handleChange(e, check)}
              />
              <label className={` tw-pl-2 tw-font-sans tw-font-light `}>
                {check.name}
              </label>
            </div>
          ))}
        </>
      )} */}
    </div>
  )
}

export default CheckList
