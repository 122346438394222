import React from "react"
import { CustomMarkdown } from "../../../plugins/sanitizehtml"
import Button from "../../../components/Button"
import Url from "../../../components/common/Url"
import Img from "../../../components/Img"
import * as style from "./Partners.module.scss"

const Partners = ({ data, lang, type }) => {
  const { title, partners, cta } = data
  // console.log(data)
  return (
    <div
      className={`my-5 ${
        type === "affiliate" ? style.partners : style.guidePartners
      }`}
    >
      <h2 className={`ming mb-5 text-center`}>
        <CustomMarkdown html={title} color={`copper`} />
      </h2>
      <div className={`${style.logoList} d-grid`}>
        {partners?.map((p, index) => {
          return p.slug ? (
            <Url key={index} url={"/partners/" + p.slug} lang={lang}>
              <Img image={p.logo} className={`${style.logo} mb-3`} />
            </Url>
          ) : (
            <Img key={index} image={p.logo} className={`${style.logo} mb-3`} />
          )
        })}
      </div>
      {cta && (
        <div className={`text-center mt-4`}>
          <Button
            lang={lang}
            type={cta.template}
            text={cta.buttonLabel}
            url={cta.url}
          />
        </div>
      )}
    </div>
  )
}

export default Partners
