import React, { useEffect, useRef, useState } from "react"
import { mdiChevronDown, mdiChevronUp } from "@mdi/js"
import Icon from "@mdi/react"

const MobileDropdown = ({ stepArray, selected, page, anchor, temp }) => {
  const { isSelectedStep, setIsSelectedStep } = selected
  const { setStepPage } = page
  const { isAnchor, setIsAnchor } = anchor
  const [isOpen, setIsOpen] = useState(false)
  const { setTempId } = temp
  const ref = useRef(null)

  const handleClickOutside = event => {
    const isMobile = window.innerWidth < 1023
    if (ref.current && !ref.current.contains(event.target) && isMobile) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, true)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, true)
    }
  })

  const toggling = () => setIsOpen(!isOpen)

  const onOptionClicked = item => {
    setIsSelectedStep(item)
    setIsAnchor({
      ...isAnchor,
      stepIndex: stepArray.indexOf(item),
      pageIndex: 0,
      elementId: item.guide_step_items[0]?.guideContent[0].id,
    })
    setTempId(item.guide_step_items[0]?.guideContent[0].id)
    setIsSelectedStep(item)
    setStepPage(item.guide_step_items[0])
    setIsOpen(false)
  }
  return (
    <div
      ref={ref}
      className="tw-fixed tw-top-[72px] tw-z-50 tw-w-full md:tw-block tw-hidden tw-bg-white tw-shadow-md"
    >
      <div
        className={`tw-flex tw-justify-between tw-content-center tw-al tw-p-1 tw-px-6 ${
          isOpen && "tw-border-solid tw-border-0 tw-border-b-2 tw-border-black"
        }`}
        onClick={toggling}
        onKeyDown={toggling}
        role={`button`}
        tabIndex={-1}
      >
        <div className="tw-text-lg">{isSelectedStep.stepName}</div>
        <Icon path={isOpen ? mdiChevronUp : mdiChevronDown} size={1} />
      </div>
      {isOpen && (
        <div>
          {stepArray
            .filter(el => el.stepName !== isSelectedStep.stepName)
            .map((item, index) => (
              <div
                onClick={() => onOptionClicked(item)}
                onKeyDown={() => onOptionClicked(item)}
                role={`button`}
                tabIndex={-index - 1}
                key={index}
                className=" tw-p-1 tw-pl-6 tw-text-lg"
              >
                {item.stepName}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

export default MobileDropdown
