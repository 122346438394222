import React from "react"
import CaseStudy from "./CaseStudy"
import GlossaryTips from "./GlossaryTips"
import InfoBox from "./InfoBox"

const RightCol = ({ itemId, item, inView, advisor, lang, location }) => {
  return (
    <>
      <div
        className={` ${
          inView.includes(itemId)
            ? "tw-block tw-animate-fade-in-left"
            : "tw-hidden"
        } `}
      >
        {item?.glossary_tips.map((glossary, index) => (
          <React.Fragment key={index}>
            <GlossaryTips content={glossary.content} />
          </React.Fragment>
        ))}
      </div>
      <div
        className={` ${
          inView.includes(itemId)
            ? "tw-block tw-animate-fade-in-left"
            : "tw-hidden"
        } `}
      >
        {item?.highlight.map((content, index) => (
          <React.Fragment key={index}>
            <InfoBox
              content={content}
              advisor={advisor}
              lang={lang}
              location={location}
            />
          </React.Fragment>
        ))}
      </div>
      <div
        className={` ${
          inView.includes(itemId)
            ? "tw-block tw-animate-fade-in-left"
            : "tw-hidden"
        } `}
      >
        {item?.case_study.map((content, index) => (
          <React.Fragment key={index}>
            <CaseStudy content={content} />
          </React.Fragment>
        ))}
      </div>
    </>
  )
}

export default RightCol
